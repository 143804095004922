<template>
  <div class="main-box">
    <div class="main-left">
      <div class="logo-box">
        <img class="logo"
             src="../assets/logo.png"
             alt="">
        <div class="name-box">
          <div class="name-chinese">老兵老兵网</div>
          <div class="name-english">LAOBINGLAOBING</div>
        </div>
      </div>
      <div class="input-box">
        <input class="input"
               type="text"
               placeholder="请输入您想了解的内容">
        <i class="el-icon-search"></i>
      </div>
    </div>
    <div class="tell-box">
      <img class="tell"
           src="../assets/tell.png"
           alt="">
      <div class="line"></div>
      <div class="tell-right">
        <div class="tell-text">服务热线</div>
        <div class="tell-num">400-616-7277</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss">
.main-box {
  // min-width: 1000px;
  margin: auto;
  width: 58%;
  background-color: #f7f7f7;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  .main-left {
    display: flex;
    align-items: center;
  }
  .logo-box {
    display: flex;
    align-items: center;
    margin-right: 30px;
    .logo {
      width: 56px;
      height: 56px;
      margin-right: 10px;
    }
    .name-box {
      .name-chinese {
        color: #000;
        font-size: 30px;
        font-weight: bold;
      }
      .name-english {
        color: #333;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .input-box {
    border: 1px solid #f40205;
    border-radius: 24px;
    width: 403px;
    height: 45px;
    padding: 2px 20px;
    box-sizing: border-box;
    .el-icon-search {
      color: #f40205;
      font-size: 22px;
    }
    .input {
      background-color: #f7f7f7;
      width: 90%;
      height: 100%;
      border: none;
      font-size: 20px;
    }
    input:focus {
      outline: none;
    }
  }
  .tell-box {
    display: flex;
    align-items: center;
    .tell {
      width: 47px;
      height: 47px;
    }
    .line {
      width: 1px;
      height: 46px;
      background-color: #f40205;
      margin: 0 15px;
    }
    .tell-right {
      color: #ff3e49;
      text-align: left;
      font-size: 18px;
      line-height: 24px;
      .tell-num {
        font-size: 24px;
        line-height: 31px;
      }
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .main-box {
    width: 100% !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .main-box {
    .logo-box {
      margin-right: 10px !important;
      .logo {
        width: 50px !important;
        height: 50px !important;
        margin-right: 5px !important;
      }
    }
    .name-box {
      .name-chinese {
        font-size: 26px !important;
      }
      .name-english {
        font-size: 14px !important;
      }
    }
    .tell-box {
      .tell {
        width: 40px !important;
        height: 40px !important;
      }
      .line {
        height: 40px !important;
        margin: 0 10px !important;
      }
      .tell-right {
        font-size: 16px !important;
        line-height: 20px !important;
        .tell-num {
          font-size: 20px !important;
          line-height: 24px !important;
        }
      }
    }
    .input-box {
      width: 300px !important;
      padding: 4px 15px !important;
    }
  }
}
</style>
