<template>
  <el-carousel height="676px"
               indicator-position="outside">
    <el-carousel-item v-for="(item,i) in bannerList"
                      :key="i">
      <img class="img"
           :src="item.image"
           alt="">
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [],
    }
  },
  methods: {
    getBanner() {
      this.axios
        .post('/api/index/getBanner', { pos_id: '1' })
        .then((res) => {
          this.bannerList = res
          this.bannerList.forEach((item) => {
            item.image = 'https://pc.lblb.wang/' + item.image
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getBanner()
  },
}
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
}
::v-deep .el-carousel__arrow {
  // display: block !important;
  width: 60px;
  height: 100px;
  border-radius: 6px;
  .el-icon-arrow-left,
  .el-icon-arrow-right {
    font-size: 50px;
  }
}
</style>