<template>
  <div class="home">
    <Top />
    <div style="position: relative;">
      <Banner />
      <NavBar style="z-index: 9;" />
    </div>
    <div class="main">
      <div class="intro">
        <div class="intro-box">
          <div class="intro-left">
            <div class="left-eng">
              ABOUT US
            </div>
            <template v-for="(i,n) in indexList">
              <div v-if="i.id===1"
                   class="left-chn"
                   :key="i.id">
                {{ i.name }}
              </div>
              <div v-if="i.id===1"
                   class="left-content"
                   :key="n">
                <pre style="white-space: pre-wrap;">{{ i.value }}</pre>
              </div>
            </template>

          </div>
          <div class="intro-right">
            <img src="../assets/floor.png"
                 alt="">
          </div>
        </div>
        <div class="entry-box">
          <div class="entry-item"
               :class="{'first':i===0}"
               v-for="(n,i) in entryList"
               :key="i">
            <div class="entry-key">{{ n.name }}</div>
            <div class="entry-val">{{ n.value }}</div>
          </div>
        </div>
      </div>
      <div class="card-box">
        <div :style="{backgroundImage:`url(${n.image})`}"
             class="card-item"
             v-for="(n,i) in cardList"
             :key="i">
          <img class="icon"
               :src="n.logo_image"
               alt="">
          <div class="card-title">{{ n.title }}</div>
          <div class="card-content">{{ n.introduce }}</div>
          <div class="card-btn"
               @click="toCardDetail(n)">查看详情 >></div>
        </div>
      </div>
      <div class="serve-box">
        <template v-for="(i,n) in indexList">
          <div v-if="i.id===6"
               :key="i.id"
               class="serve-title">
            <div class="title"> {{ i.name }}</div>
            <div class="btn"
                 @click="toServeDetail(i)">查看更多</div>
          </div>
          <div v-if="i.id===6"
               :key="n"
               class="serve-desc">
            {{ i.value }}
          </div>
        </template>
        <div class="divide"></div>
        <div class="serve-list">
          <div class="list first">
            <div :key="i"
                 v-for="(n,i) in demoList.slice(0,5)"
                 @click="toServeDetail(n)"
                 class="serve-item">
              <div class="list-box">
                <img :src="n.logo_image"
                     alt="">
                <div class="text">{{ n.name }}</div>
              </div>
            </div>
          </div>
          <div class="list second">
            <div :key="i"
                 v-for="(n,i) in demoList.slice(5,9)"
                 @click="toServeDetail(n)"
                 class="serve-item">
              <div class="list-box">
                <img :src="n.logo_image"
                     alt="">
                <div class="text">{{ n.name }}</div>
              </div>
            </div>
          </div>
          <div class="list third">
            <div class="serve-item"
                 :key="i"
                 @click="toServeDetail(n)"
                 v-for="(n,i) in demoList.slice(9,14)">
              <div class="list-box">
                <img :src="n.logo_image"
                     alt="">
                <div class="text">{{ n.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="partners-box">
        <div class="partners-eng">COMPANY</div>
        <div class="title-box">
          <div class="title">合作企业</div>
          <div class="btn"
               @click="toPartnersDetail()">查看更多</div>
        </div>
        <div class="partners-list">
          <div class="item"
               v-for="(n,i) in enterpriseList"
               :key="i"
               @click="toPartnersDetail(n)">
            <img :src="n.image"
                 alt="">
          </div>
        </div>
      </div>
      <div class="news-box">
        <div class="partners-box news">
          <div class="partners-eng">NEWS</div>
          <div class="title-box">
            <div class="title">资讯中心</div>
          </div>
          <div class="news-list">
            <div class="item"
                 v-for="(n,i) in newsList"
                 @click="toNewsDetail(n)"
                 :key="i">
              <div class="title">{{n.title }}</div>
              <div class="borders"></div>
              <img :src="n.image"
                   alt="">
              <div class="content">{{n.introduction }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <div class="bottom-list">
          <div class="bottom-item">
            <div class="title">网站首页</div>
            <div class="borders"></div>
          </div>
          <div class="bottom-item">
            <div class="title">平台介绍</div>
            <div class="borders"></div>
            <div class="item-box">
              <div v-for="(n,i) in cardLists"
                   :key="i"
                   @click="toCardDetail(n)"
                   class="item">{{ n.title }}</div>
            </div>
          </div>
          <div class="bottom-item">
            <div class="title">服务范围</div>
            <div class="borders"></div>
            <div class="item-box">
              <div v-for="(n,i) in demoList"
                   :key="i"
                   @click="toServeDetail(n)"
                   class="item">{{ n.name }}</div>
            </div>

          </div>
          <div class="bottom-item">
            <div class="title">合作企业</div>
            <div class="borders"></div>
            <div class="item-box">
              <div class="item"
                   @click="toPartnersDetail()">合作企业</div>
            </div>
          </div>
          <div class="bottom-item">
            <div class="title">资讯中心</div>
            <div class="borders"></div>
            <div class="item-box">
              <div class="item"
                   @click="toNewsDetail()">资讯中心</div>
            </div>
          </div>
          <div style="overflow: hidden;"
               class="bottom-item">
            <div class="title">联系我们</div>
            <div class="borders"></div>
            <div class="item-box">
              <div class="item"
                   @click="toTell()">联系我们</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Top from '@/components/Top.vue'
import Banner from '@/components/Banner.vue'

export default {
  name: 'HomeView',
  components: {
    NavBar,
    Banner,
    Top,
  },
  data() {
    return {
      indexList: [],
      entryList: [],
      newsList: [],
      enterpriseList: [],
      cardList: [],
      cardLists: [],
      demoList: [],
    }
  },
  methods: {
    // 联系我们
    toTell() {
      this.$router.push({ path: '/ContactUs' })
    },
    // 咨讯中心
    toNewsDetail(n) {
      if (n) {
        this.$router.push({
          path: '/zixundetail',
          query: { id: n.id },
        })
      } else {
        this.$router.push({ path: '/InformationCenter' })
      }
    },
    // 合作企业
    toPartnersDetail(n) {
      if (n) {
        this.$router.push({
          path: '/qiyedetail',
          query: { id: n.id },
        })
      } else {
        this.$router.push({ path: '/CooperativeEnterprise' })
      }
    },
    // 服务范围
    toServeDetail(n) {
      this.$router.push({ path: '/ScopeService', query: { id: n.id } })
    },
    // 平台介绍
    toCardDetail(n) {
      this.$router.push({ path: '/PlatformIntroduction', query: { id: n.id } })
    },
    // 获取banner
    getBanner() {
      this.axios
        .post('/api/index/getIndex')
        .then((res) => {
          console.log(res, 11111111)
          this.indexList = res
          this.indexList.forEach((i) => {
            if ((i.id !== 1) & (i.id !== 6)) {
              this.entryList.push(i)
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取服务范围
    getService() {
      this.axios
        .post('/api/index/getService')
        .then((res) => {
          this.demoList = res
          this.demoList.forEach((item) => {
            item.logo_image = 'https://pc.lblb.wang/' + item.logo_image
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取合作企业
    getEnterprise() {
      this.axios
        .post('/api/index/getEnterprise', { is_index: 1 })
        .then((res) => {
          this.enterpriseList = res.list
          this.enterpriseList.forEach((item) => {
            item.image = 'https://pc.lblb.wang/' + item.image
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取咨讯中心
    getNews() {
      this.axios
        .post('/api/index/getNews', { is_index: 1 })
        .then((res) => {
          this.newsList = res.list
          this.newsList.forEach((item) => {
            item.image = 'https://pc.lblb.wang/' + item.image
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取平台介绍
    getCard() {
      this.axios
        .post('/api/index/getPlate')
        .then((res) => {
          this.cardLists = res
          res.forEach((i) => {
            if (i.id !== 1) {
              this.cardList.push(i)
            }
          })
          this.cardList.forEach((i) => {
            i.logo_image = 'https://pc.lblb.wang/' + i.logo_image
          })
          this.cardList.forEach((i) => {
            i.image = 'https://pc.lblb.wang/' + i.image
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getBanner()
    this.getCard()
    this.getService()
    this.getEnterprise()
    this.getNews()
  },
}
</script>
<style lang="scss">
body {
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box;
}
.home {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #f7f7f7;
  // padding: 30px;
  .intro {
    width: 58%;
    margin: 50px auto;
    .intro-box {
      display: flex;
      justify-content: space-between;
      .intro-left {
        width: 50%;
        text-align: left;
        .left-eng {
          color: #e6e6e6;
          font-weight: bold;
          font-size: 86px;
        }
        .left-chn {
          color: #333;
          font-weight: bold;
          font-size: 48px;
          letter-spacing: 10px;
        }
        .left-content {
          margin-top: 5px;
          color: #333;
          font-size: 24px;
          line-height: 38px;
        }
      }
      .intro-right {
        display: flex;
        align-items: center;
        width: 48%;
        img {
          width: 100%;
        }
      }
    }
  }

  .entry-box {
    margin-top: -60px;
    width: 90%;
    display: flex;
    align-items: center;

    .entry-item:hover {
      background: linear-gradient(
        90deg,
        #ff3e49 0%,
        #fe3743 12%,
        #fe2635 27%,
        #fe0a1d 45%,
        #fe0115 50%,
        #fc0203 98%
      ) !important;
      .entry-key,
      .entry-val {
        color: #fff !important;
      }
    }
    .entry-item {
      width: 25%;
      padding: 10px;
      background-color: #fff;
      box-sizing: border-box;
      .entry-key {
        color: #333;
        font-size: 22px;
      }
      .entry-val {
        color: #f40205;
        font-size: 32px;
        font-weight: bold;
      }
    }
  }
  .card-box {
    display: flex;
    width: 58%;
    margin: 50px auto;
    color: #fff;
    .card-item {
      position: relative;
      width: 25%;
      height: 500px;
      padding: 18px;
      box-sizing: border-box;
      position: relative;
      text-align: left;
      .card-title {
        font-size: 26px;
        margin: 3px 0;
      }
      .card-content {
        font-size: 18px;
        line-height: 28px;
      }
      .card-btn {
        cursor: pointer;
        border-radius: 22px;
        border: 1px solid #ffffff;
        display: inline-block;
        padding: 4px 8px;
        font-size: 18px;
        position: absolute;
        bottom: 50px;
      }

      .bgimg {
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .icon {
        width: 45px;
        height: 45px;
      }
    }
    .card-item:hover {
      background: linear-gradient(
        90deg,
        #ff3e49 0%,
        #fe3743 12%,
        #fe2635 27%,
        #fe0a1d 45%,
        #fe0115 50%,
        #fc0203 98%
      ) !important;
    }
  }
  .serve-box {
    width: 58%;
    margin: 50px auto;

    .serve-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      .title {
        font-weight: bold;
        font-size: 48px;
        color: #333333;
      }
      .btn {
        cursor: pointer;
        background: #e6e6e6;
        border-radius: 22px;
        font-size: 24px;
        color: #333333;
        padding: 5px 15px;
        letter-spacing: 1px;
      }
    }
    .serve-desc {
      font-size: 26px;
      color: #333333;
      text-align: left;
    }
    .divide {
      width: 100%;
      border: 2px solid #e6e6e6;
      margin: 40px 0;
    }
    .serve-list {
      margin-top: 60px;
      background-image: url('../assets/serImg.png');
      background-size: cover;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .serve-item {
        cursor: pointer;
        background: #fff;
        width: 140px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 10px;
        transform: rotate(45deg);
        .list-box {
          transform: rotate(-45deg);
        }
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
        }
        .text {
          font-size: 24px;
          color: #000;
        }
      }
    }
    .second {
      justify-content: space-evenly;
    }
  }
  .partners-box {
    width: 58%;
    margin: 50px auto;
    .partners-eng {
      text-align: left;
      color: #e6e6e6;
      font-weight: bold;
      font-size: 86px;
    }
    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      .title {
        font-weight: bold;
        font-size: 48px;
        color: #333333;
      }
      .btn {
        cursor: pointer;
        background: #e6e6e6;
        border-radius: 22px;
        font-size: 24px;
        color: #333333;
        padding: 5px 15px;
        letter-spacing: 1px;
      }
    }
    .partners-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 20px 0;
      .item {
        cursor: pointer;
        width: 18%;
        margin: 10px 0;
        img {
          width: 100%;
        }
      }
    }
  }
  .news-box {
    background-color: #fff;
    width: 100%;
  }
  .news {
    padding: 20px 0;
    width: 58%;
    margin: 50px auto 0 auto;
    .news-list {
      margin-top: 70px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        cursor: pointer;
        width: 23%;
        .title {
          height: 45px;
          text-align: left;
          color: #333;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
        }
        .title:hover {
          color: #f40205 !important;
        }
        .borders {
          width: 32px;
          height: 6px;
          margin: 14px 0;
          background: linear-gradient(
            90deg,
            #ff3e49 0%,
            #fe3743 12%,
            #fe2635 27%,
            #fe0a1d 45%,
            #fe0115 50%,
            #fc0203 98%
          );
        }
        img {
          width: 100%;
        }
        .content {
          height: 45px;
          text-align: left;
          color: #333;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          margin: 14px 0;
        }
      }
    }
  }
  .bottom-box {
    padding: 10px 0;
    background-color: #e6e6e6;
    .bottom-list {
      overflow: hidden;
      width: 58%;
      margin: 20px auto 50px auto;
      display: flex;
      justify-content: space-around;
      .bottom-item {
        text-align: center;
        .title {
          color: #999;
          font-size: 20px;
        }
        .borders {
          width: 100%;
          margin: 20px auto;
          height: 1px;
          background-color: #ccc;
        }
        .item-box {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 300px;
        }

        .item {
          cursor: pointer;
          color: #999;
          font-size: 16px;
          margin: 10px auto;
          padding: 0 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .intro,
  .bottom-list,
  .news,
  .partners-box,
  .serve-box,
  .card-box,
  .entry-box {
    width: 80% !important;
  }
  .intro-box {
    .intro-left {
      .left-eng {
        font-size: 65px !important;
      }
      .left-chn {
        font-size: 42px !important;
        letter-spacing: 5px !important;
      }
      .left-content {
        font-size: 26px !important;
        line-height: 30px !important;
      }
    }
  }

  .entry-item {
    .entry-key {
      font-size: 20px !important;
    }
    .entry-val {
      font-size: 26px !important;
    }
  }
  .card-btn {
    bottom: 30px !important;
  }
  .serve-item {
    width: 80px !important;
    height: 80px !important;
    img {
      width: 40px !important;
      height: 40px !important;
    }
    .text {
      font-size: 12px !important;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .intro {
    width: 58% !important;
  }
  .intro-box {
    .intro-left {
      .left-eng {
        font-size: 52px !important;
      }
      .left-chn {
        font-size: 34px !important;
        letter-spacing: 5px !important;
      }
      .left-content {
        font-size: 22px !important;
        line-height: 28px !important;
      }
    }
  }
  .entry-box {
    margin-top: -80px !important;
  }
  .entry-item {
    .entry-key {
      font-size: 16px !important;
    }
    .entry-val {
      font-size: 24px !important;
    }
  }
  .serve-item {
    width: 100px !important;
    height: 100px !important;
    img {
      width: 55px !important;
      height: 55px !important;
    }
    .text {
      font-size: 18px !important;
    }
  }
}
</style>
