import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/PlatformIntroduction",
    name: "PlatformIntroduction",
    component: () =>
      import("../views/PlatformIntroduction.vue"),
	  meta: {  title: '平台介绍', }
  },
  {
    path: "/ScopeService",
    name: "ScopeService",
    component: () =>
      import("../views/ScopeService.vue"),
  	  meta: {  title: '服务范围', }
  },
  {
    path: "/CooperativeEnterprise",
    name: "CooperativeEnterprise",
    component: () =>
      import("../views/CooperativeEnterprise.vue"),
  	  meta: {  title: '合作企业', }
  },
  {
    path: "/InformationCenter",
    name: "InformationCenter",
    component: () =>
      import("../views/InformationCenter.vue"),
  	  meta: {  title: '资讯中心', }
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: () =>
      import("../views/ContactUs.vue"),
  	  meta: {  title: '联系我们', }
  },
  {
    path: "/qiyedetail",
    name: "qiyedetail",
    component: () =>
      import("../views/qiyedetail.vue"),
  	  meta: {  title: '企业详情', }
  },
  {
    path: "/zixundetail",
    name: "zixundetail",
    component: () =>
      import("../views/zixundetail.vue"),
  	  meta: {  title: '资讯详情', }
  }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
