<template>
  <div class="nav-bar">
    <img class="nav-bar-img"
         src="../../static/img/pcnew1.png"
         alt="" />
    <div :class="index == menuIndex?'acho': ''"
         class="nav-bar-div"
         v-for="(item, index) in navList"
         :key="index">
      <a :href="item.link">{{ item.name }}</a>
    </div>
  </div>
</template>
 
<script>
export default {
  name: 'NavBar',
  props: {
    menuIndex: String,
  },
  data() {
    return {
      navList: [
        { name: '首页', link: '/' },
        { name: '平台介绍', link: '/PlatformIntroduction' },
        { name: '服务范围', link: '/ScopeService' },
        { name: '合作企业', link: '/CooperativeEnterprise' },
        { name: '资讯中心', link: '/InformationCenter' },
        { name: '联系我们', link: '/ContactUs' },
      ],
    }
  },
}
</script>
 
<style scoped lang="scss">
.nav-bar {
  display: flex;
  align-items: center;
  position: absolute;
  left: 21%;
  right: 21%;
  top: 0;
  background: linear-gradient(
    to right,
    #ff3e49,
    #fe3743,
    #fe2635,
    #fe0a1d,
    #fe0115,
    #fc0203
  );
  padding: 0 12.7% 0px 1.5%;
  justify-content: space-between;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
}
.nav-bar-img {
  width: 66px;
  height: 66px;
}
.nav-bar-div {
  height: 86px;
  position: relative;
  line-height: 86px;
  box-sizing: border-box;
}
.acho ::after {
  position: absolute;
  left: calc(50% - 15px);
  right: calc(50% - 15px);
  height: 3px;
  bottom: 0;
  background-color: #ffffff;
  content: '';
}
.nav-bar-div:hover {
  &::after {
    position: absolute;
    left: calc(50% - 15px);
    right: calc(50% - 15px);
    height: 3px;
    bottom: 0;
    background-color: #ffffff;
    content: '';
  }
}
.nav-bar-div a {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
</style>